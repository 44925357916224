import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactOutsideEvent from 'react-outside-event';

import './style.css';

const DrawerOutsideHOC = ReactOutsideEvent(
  class DrawerOutside extends React.Component {
    // eslint-disable-next-line react/static-property-placement
    static propTypes = {
      children: PropTypes.any,
      onOutsideEvent: PropTypes.func
    };

    onOutsideEvent = (event) => {
      if (this.props.onOutsideEvent) {
        this.props.onOutsideEvent(event);
      }
    };

    render() {
      return this.props.children;
    }
  }
);

function Drawer(props) {
  const [state, setState] = useState({
    open: props.open,
    drawerIsOpen: props.open
  });

  const elRef = useRef(null);
  const prevOpenRef = useRef(props.open);
  let contentClassName = 'r-drawer__content';
  let overlayClassName = 'r-drawer__overlay';
  const anchor = props.anchor || 'top';

  if (prevOpenRef.current !== props.open) {
    prevOpenRef.current = props.open;
    state.open = props.open;
    state.drawerIsOpen = true;
  }

  contentClassName += ` r-drawer__content-${anchor}`;

  if (state.open) {
    contentClassName += ` r-drawer__content-${anchor}--open`;
    overlayClassName += ' r-drawer__overlay--open';
  }

  if (props.fullscreen) {
    contentClassName += ' r-drawer--fullscreen';
    overlayClassName += ' r-drawer--fullscreen';
  }

  if (props.contentClassName) {
    contentClassName += ` ${props.contentClassName}`;
  }

  if (props.overlayClassName) {
    overlayClassName += ` ${props.overlayClassName}`;
  }

  const handleTransitionEnd = useCallback((event) => {
    if (event.target === elRef.current) {
      setState((prevState) => {
        if (!prevState.open) {
          if (props.onClosed) {
            props.onClosed();
          }

          return {
            ...prevState,
            drawerIsOpen: false
          };
        }

        return prevState;
      });
    }
  }, []);

  const handleClose = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      open: false
    }));

    if (props.onClose) {
      props.onClose();
    }
  }, []);

  React.useEffect(() => {
    elRef.current.addEventListener('transitionend', handleTransitionEnd);

    return () => {
      if (elRef.current !== null) {
        elRef.current.removeEventListener('transitionend', handleTransitionEnd);
      }
    };
  }, []);

  return (
    <>
      {props.overlay && <div className={overlayClassName} />}
      <DrawerOutsideHOC onOutsideEvent={props.disabled ? null : handleClose}>
        <div ref={elRef} className={contentClassName}>
          {(state.drawerIsOpen || props.persistent) && props.children}
        </div>
      </DrawerOutsideHOC>
    </>
  );
}

Drawer.propTypes = {
  /** Child elements of this component. */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  /** CSS class to add to the component's overlay element. */
  contentClassName: PropTypes.string,
  /** CSS class to add to the component's overlay element. */
  overlayClassName: PropTypes.string,
  /** Drawer takes over the entire screen */
  fullscreen: PropTypes.bool,
  /** Location side of where the drawer will be placed. Default: top */
  anchor: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
  /** Display the drawer  */
  open: PropTypes.bool,
  /** Display the overlay modal */
  overlay: PropTypes.bool,
  /** Disable all drawer funtionality  */
  disabled: PropTypes.bool,
  /** Callback: Immediately called when close is called. */
  onClose: PropTypes.func,
  /** Callback:Called when drawer is fully closed. */
  onClosed: PropTypes.func,
  /** Children persist when drawer is closed */
  persistent: PropTypes.bool
};

export default Drawer;
