import React from 'react';
import TimesIcon from '@components/deprecatedTookit/icons/fa/light/TimesIcon';
import IconButton from '../IconButton';

import './style.css';

interface DialogCardProps {
  className: string;
  children: any;
  onClose?: any;
}

function DialogCard(props: DialogCardProps) {
  const baseClassName = 'DialogCard';
  let className = baseClassName;

  if (props.className) {
    className += ` ${props.className}`;
  }

  const handleCloseBtn = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <div
      className={className}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      {props.onClose && (
        <IconButton
          variant="text"
          transparent
          className={`${baseClassName}__close-btn`}
          icon={<TimesIcon />}
          onClick={handleCloseBtn}
        />
      )}
      {props.children}
    </div>
  );
}

export default DialogCard;
