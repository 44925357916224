// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DialogCard {
  position: relative;
  display: flex;
  background-color: white;
  border: 1px solid #d9dbde;
  box-shadow: 0 4px 10px rgb(0 0 0 / 10%);
  border-radius: 2px;
  padding: 36px;
  min-width: 56px;
}

  .DialogCard__close-btn {
    position: absolute;
    top: 18px;
    right: 18px;
    padding: 0;
    min-width: 20px;
    min-height: 20px;
  }

  .DialogCard__close-btn * {
      min-width: 20px;
      min-height: 20px;
      width: 20px;
      height: 20px;
      color: #9fa2a5;
    }
`, "",{"version":3,"sources":["webpack://./app/components/DialogCard/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,yBAAyB;EACzB,uCAAuC;EACvC,kBAAkB;EAClB,aAAa;EACb,eAAe;AAkBjB;;EAhBE;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,UAAU;IACV,eAAe;IACf,gBAAgB;EASlB;;EAPE;MACE,eAAe;MACf,gBAAgB;MAChB,WAAW;MACX,YAAY;MACZ,cAAc;IAChB","sourcesContent":[".DialogCard {\n  position: relative;\n  display: flex;\n  background-color: white;\n  border: 1px solid #d9dbde;\n  box-shadow: 0 4px 10px rgb(0 0 0 / 10%);\n  border-radius: 2px;\n  padding: 36px;\n  min-width: 56px;\n\n  &__close-btn {\n    position: absolute;\n    top: 18px;\n    right: 18px;\n    padding: 0;\n    min-width: 20px;\n    min-height: 20px;\n\n    & * {\n      min-width: 20px;\n      min-height: 20px;\n      width: 20px;\n      height: 20px;\n      color: #9fa2a5;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
